import * as React from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'react-bootstrap';
import ButtonComponent from '../../utils/ButtonComponent';
import LinkComponent from '../../utils/linkComponent';
import footerBanner from '../../images/footerBanner.webp';

const AcknowledgementStyled = styled.div`
	//margin-bottom: 1rem;

	.sponsorsFooter {
		background-color: var(--light_overlay);
	}
	p {
		margin-top: 1rem;
	}

	.FooterText {
		color: var(--neutral_1) !important;
		padding-right: 3em;
	}
	.sizeFooterText {
		font-size: var(--font-size-lg);
	}
	.boldFooterText {
		font-weight: bold;
	}
	.whiteColor {
		color: var(--secondary_6) !important;
	}
	.Container {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		padding: 1em 5em;
		height: 10em;
		display: flex;
		align-items: center;
	}
	.btn {
		font-weight: bold;
		float: right;
		white-space: nowrap;
		width: 100%;
		color: var(--neutral_1);
		font-size: var(--font-size-md);
	}

	.btn-primary:hover {
		color: var(--primary);
	}

	.footerBtn {
		padding-left: 1em;
	}

	// mobile settings
	@media (max-width: 768px) {
		.Container {
			padding: 2em;
		}
		.FooterText {
			padding-right: 0em;
		}
		.Container {
			flex-direction: column;
			padding: 1em 2em;
			height: auto;
		}
	}
`;

export default function Acknowledgement() {
	// check the page is 'THETA Newsletter' or not
	// remove the register your interest to attend THETA Banner from the registration page
	if (
		typeof window !== 'undefined' &&
		window.location.pathname !== '/registration/'
	) {
		return (
			<AcknowledgementStyled>
				<div
					style={{
						backgroundImage: `url(${footerBanner})`,
						backgroundSize: 'cover',
					}}
				>
					{/* <Container> */}
					<div className='Container'>
						<div className='FooterText'>
							<p className='sizeFooterText whiteColor boldFooterText marginTop'>
								Register to attend THETA 2025
							</p>
						</div>
						<div className='footerBtn'>
							<LinkComponent
								onClick={() => window.scrollTo(0, 0)}
								to='/registration/'
							>
								<ButtonComponent buttonName='Register'></ButtonComponent>
							</LinkComponent>
						</div>
					</div>
					{/* </Container> */}
				</div>
				{/* <div className='sponsorsFooter'>
				<div className='wrapper'>
					<div className='FooterText'>
						<p className='sizeFooterText boldFooterText'>Sponsors</p>
						<p>
							Promote your organisation at THETA 2025. Take advantage of the
							opportunity to showcase your brand in-front of the sector's
							leading minds and decision makers.
						</p>
					</div>
					<div className='footerBtn marginTop'>
						<ButtonComponent buttonName='Book Now'></ButtonComponent>
					</div>
				</div>
			</div> */}
			</AcknowledgementStyled>
		);
	}
}
